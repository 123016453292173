<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        type="submit"
        color="primary"
        outlined
        block
        small
        v-bind="attrs"
        v-on="on"
        :loading="loading"
        @click="showDialog = true"
        >Olvidé mi contraseña 😅</v-btn
      >
    </template>

    <v-card>
      <v-toolbar flat dense color="primary" dark
        >Restablecer contraseña</v-toolbar
      >
      <v-form v-on:submit.prevent>
        <v-card-text style="padding-bottom: 0px">
          <p>Para restablecer la contraseña, ingrese su email:</p>
          <v-text-field
            v-model="correo"
            prepend-icon="mdi-email"
            outlined
            dense
            name="login"
            label="Email:"
            type="text"
            :error-messages="correoErrors"
            :disabled="loading || correoEnviado"
            @input="validarCorreo()"
            @blur="validarCorreo()"
          >
          </v-text-field>
          <vue-recaptcha
            sitekey="6LdwLLYZAAAAADRkHh_ptXA6rtFW-4s2I2hTPzzV"
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="verifyRecaptcha"
            @expired="recaptchaToken = null"
            @error="recaptchaToken = null"
          />
        </v-card-text>

        <v-card-text v-if="correoEnviado" style="padding-top: 0px">
          Se ha enviado un correo de recuperación de contraseña.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small @click="showDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            dark
            small
            :class="{ 'disable-events': blockSendButton || correoEnviado }"
            @click="recuperarPassword()"
            >Recuperar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

import { enviarCorreoRecuperacionService } from "./login.service";

export default {
  name: "recoveryPassword",
  components: { VueRecaptcha },
  mixins: [validationMixin],
  validations: {
    correo: { required, email },
  },
  computed: {
    blockSendButton() {
      return this.correoErrors.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      correo: "",
      recaptchaToken: null,
      correoErrors: [],
      correoEnviado: false,
    };
  },
  methods: {
    validarCorreo() {
      this.correoErrors = [];
      this.$v.correo.$touch();
      !this.$v.correo.required && this.correoErrors.push("Campo requerido.");
      !this.$v.correo.email && this.correoErrors.push("Correo no válido.");
    },
    verifyRecaptcha(response) {
      this.recaptchaToken = response;
    },

    async recuperarPassword() {
      try {
        this.validarCorreo();
        if (this.correoErrors.length == 0) {
          const sendData = {
            correo: this.correo,
            recaptchaToken: this.recaptchaToken,
          };
          this.loading = true;
          const serverResponse = await enviarCorreoRecuperacionService(
            sendData
          );
          this.loading = false;
          if (!serverResponse.ok) {
            if (serverResponse.correoError)
              this.correoErrors.push(serverResponse.mensaje);
            else this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.correoEnviado = true;
          }
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
  },
};
</script>
