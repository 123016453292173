<template>
  <v-app id="inspire">
    <v-container fluid class="fill-height" style="background-color: #0d3863">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="6" lg="5">
          <v-card class="elevation-12">
            <v-row align="center" justify="center" style="background: #eeeeee">
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-card outlined flat color="grey lighten-3">
                  <v-img
                    src="images/logo_agnus.png"
                    max-height="200"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="8" lg="8" style="padding: 0px">
                <loginFormComponent />
                <v-card flat>
                  <v-card-text>
                    <recoveryPasswordComponent />
                  </v-card-text>
                  <v-card-text class="text-center">
                    ¿No tienes cuenta?
                    <router-link to="/registro">Regístrate</router-link>
                  </v-card-text>
                  <v-divider class="mx-3"></v-divider>
                  <v-card-text>
                    <div style="padding-top: 10px" class="text-center">
                      <legalDialogComponent 
                        title="Aviso de privacidad, términos y condiciones"
                        clave="TYC"
                      /><br>
                      <legalDialogComponent 
                        title="Aviso de confidencialidad"
                        clave="ACON"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>


      <systemMessageComponent />
    </v-container>

  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import loginFormComponent from "./loginForm.vue";
import recoveryPasswordComponent from "./recoveryPassword.vue";
import systemMessageComponent from "../globals/template/systemMessage.vue";
import legalDialogComponent from "./legalDialog.vue";

export default {
  name: "loginView",
  components: {
    loginFormComponent,
    recoveryPasswordComponent,
    systemMessageComponent,
    legalDialogComponent
  },
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "colors"]),
  },
  data() {
    return {
      activaError: false,
      mailEnviado: false,
    };
  },
  created() {
    if (sessionStorage.getItem("agnus-token")) {
      this.$store.dispatch("setSessionToken", sessionStorage.getItem("agnus-token"));
      this.$router.push("/dashboard");
    }
    if (localStorage.getItem("agnus-token")) {
      this.$store.dispatch("setSessionToken", localStorage.getItem("agnus-token"));
      this.$router.push("/dashboard");
    }
  },

};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
.errorMessage {
  color: #ff0000;
}
.invalid_input {
  border: 1px solid red;
}

.link {
  text-decoration: none;
}
</style>