<template>
  <form v-on:submit.prevent>
    <v-card flat>
      <v-card-title class="text-center">
        <v-spacer />Iniciar sesión <v-spacer />
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <v-text-field
          v-model.trim="correo"
          prepend-icon="mdi-at"
          name="login_correo"
          label="Correo"
          type="text"
          outlined
          dense
          :disabled="loading"
          :error-messages="correoErrors"
          @input="validarCorreo()"
          @blur="validarCorreo()"
        >
        </v-text-field>
        <v-text-field
          v-model="password"
          id="password"
          prepend-icon="mdi-lock"
          name="login_password"
          label="Contraseña"
          type="password"
          outlined
          dense
          :disabled="loading"
          :error-messages="passwordErrors"
          @input="validarPassword()"
          @blur="validarPassword()"
        >
        </v-text-field>
        <div v-if="errorMessage" class="text-center errorMessage">
          {{ errorMessage }}
        </div>
        <vue-recaptcha
          sitekey="6LdwLLYZAAAAADRkHh_ptXA6rtFW-4s2I2hTPzzV"
          :loadRecaptchaScript="true"
          ref="recaptcha"
          @verify="verifyRecaptcha"
          @expired="recaptchaToken = null"
          @error="recaptchaToken = null"
        >
        </vue-recaptcha>
      </v-card-text>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          @click="submit()"
          block
          :loading="loading"
          :class="{ 'disable-events': blockSendButton || !recaptchaToken }"
          >Iniciar sesión</v-btn
        >
      </v-card-text>
    </v-card>

    <cuentaInactivaComponent
      v-if="cuentaInactiva"
      :mostrar="cuentaInactiva"
      :correo="correo"
      @cerrar="cuentaInactiva = false"
    />
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

import cuentaInactivaComponent from "./cuentaInactiva.vue";
import { iniciarSesionService } from "./login.service";

export default {
  name: "loginForm",
  components: { VueRecaptcha, cuentaInactivaComponent },
  mixins: [validationMixin],
  validations: {
    correo: { required, email },
    password: { required, minLength: minLength(5) },
  },
  computed: {
    blockSendButton() {
      return this.correoErrors.length > 0 || this.passwordErrors.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      correo: "",
      password: "",
      recaptchaToken: null,
      errorMessage: null,
      correoErrors: [],
      passwordErrors: [],
      cuentaInactiva: false,
    };
  },
  methods: {
    validarCorreo() {
      this.correoErrors = [];
      this.$v.correo.$touch();
      !this.$v.correo.required && this.correoErrors.push("Campo requerido.");
      !this.$v.correo.email && this.correoErrors.push("Correo no válido.");
    },
    validarPassword() {
      this.passwordErrors = [];
      this.$v.password.$touch();
      !this.$v.password.required &&
        this.passwordErrors.push("Campo requerido.");
      !this.$v.password.minLength &&
        this.passwordErrors.push("Constraseña no válida.");
    },
    verifyRecaptcha(response) {
      this.recaptchaToken = response;
    },

    async submit() {
      try {
        this.errorMessage = null;
        this.validarCorreo();
        this.validarPassword();
        if (this.correoErrors.length == 0 && this.passwordErrors.length == 0) {
          const sendData = {
            correo: this.correo,
            password: this.password,
            recaptchaToken: this.recaptchaToken,
          };
          this.loading = true;
          const serverResponse = await iniciarSesionService(sendData);
          this.loading = false;
          if (!serverResponse.ok) {
            if (serverResponse.cuentaInactiva) this.cuentaInactiva = true;
            else this.errorMessage = serverResponse.mensaje;
          } else {
            this.$store.dispatch("setSessionToken", serverResponse.token);
            localStorage.setItem("agnus-token", serverResponse.token);
            this.$router.push("/dashboard");
          }
        }
      } catch (error) {
        if (!error.handdled) this.$appErrorMessage();
      }
    },
  },
};
</script>
