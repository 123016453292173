import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VContainer,{staticClass:"fill-height",staticStyle:{"background-color":"#0d3863"},attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"11","sm":"8","md":"6","lg":"5"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VRow,{staticStyle:{"background":"#eeeeee"},attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c(VCard,{attrs:{"outlined":"","flat":"","color":"grey lighten-3"}},[_c(VImg,{attrs:{"src":"images/logo_agnus.png","max-height":"200","contain":""}})],1)],1),_c(VCol,{staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[_c('loginFormComponent'),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('recoveryPasswordComponent')],1),_c(VCardText,{staticClass:"text-center"},[_vm._v(" ¿No tienes cuenta? "),_c('router-link',{attrs:{"to":"/registro"}},[_vm._v("Regístrate")])],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,[_c('div',{staticClass:"text-center",staticStyle:{"padding-top":"10px"}},[_c('legalDialogComponent',{attrs:{"title":"Aviso de privacidad, términos y condiciones","clave":"TYC"}}),_c('br'),_c('legalDialogComponent',{attrs:{"title":"Aviso de confidencialidad","clave":"ACON"}})],1)])],1)],1)],1)],1)],1)],1),_c('systemMessageComponent')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }