<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card class="elevation-12">
      <v-toolbar color="primary" dark dense flat
        ><h3>Ooops! Cuenta inactiva</h3></v-toolbar
      >
      <v-card-text>
        <div class="text--primary" style="padding-top: 10px">
          Para activar tu cuenta, favor de verificar el correo que ha sido
          enviado a {{ correo }}
        </div>
      </v-card-text>
      <v-card-text
        >Si no encuentra el correo, revise en la sección de "No deseado" o
        "Spam" y márquelo como correo deseado.</v-card-text
      >
      <v-divider v-if="correoEnviado"></v-divider>
      <v-card-text v-if="correoEnviado">
        <h3>El correo se ha enviado correctamente</h3>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cerrar')"
          >Cerrar</v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-events': correoEnviado }"
          @click="enviarCorreo()"
          >Reenviar correo</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { enviarCorreoActivacionService } from "./login.service";

export default {
  name: "cuentaInactiva",
  props: {
    mostrar: Boolean,
    correo: String,
  },
  data() {
    return {
      loading: false,
      correoEnviado: false,
    };
  },
  methods: {
    async enviarCorreo() {
      try {
        this.loading = true;
        const serverResponse = await enviarCorreoActivacionService(this.correo);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.correoEnviado = true;
      } catch (error) {
        if (!error.handdled) this.$appErrorMessage();
      }
    },
  },
};
</script>
